body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .slick-dots li {
  background-color: white !important;
} */

.slick-dots li button:before {
  color: white !important;
  font-size: 10px !important;
  opacity: 1 !important;
}

.slick-dots li.slick-active button:before {
  color: black !important;
}

.slick-list {
  margin-bottom: 10px !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
